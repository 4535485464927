<template>
  <div>
    <vx-card class="mb-base">
      <div class="vx-breadcrumb md:block hidden mb-4">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7" />
                  <polyline points="6 17 11 12 6 7" />
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'director-learning-centre-view', params: { id: centerId }}"
            >{{centreName}}</router-link>
            <span class="breadcrumb-separator mx-2 flex items-start">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7" />
                  <polyline points="6 17 11 12 6 7" />
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <router-link :to="{ name: 'director-room-list', params: { id: centerId }}">Rooms</router-link>
            <span class="breadcrumb-separator mx-2 flex items-start">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7" />
                  <polyline points="6 17 11 12 6 7" />
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>{{room.name}}</a>
          </li>
        </ul>
      </div>
      <h2 class="mb-4">Rooms Details</h2>

       <div class="w-full mr-5" align="right">
            <vs-button class="mt-5 mb-md-0 mb-2" align="right" color="primary" @click="editRoom(room._id)" >Edit </vs-button>
            <vs-button class="mt-5 mb-md-0 mb-2 ml-5" align="right" color="danger" @click="deleteRoom(room._id)">Delete </vs-button>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
           <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <div class="w-full mb-5">
                <div class="img-container w-64">               
                  <img v-bind:src="room.logo"  alt="img" class="responsive" v-if="room.logo && room.logo != 'test.jop' && room.logo != 'logo' ">
                  <img v-bind:src="$defaultImage"  alt="img" class="responsive" v-else>
                </div>
              </div>
            </vs-col>
        </vs-row>
      <!--<h2 class="mb-5">Rooms</h2>-->
      <vs-row vs-w="5">
        <vs-col vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full mb-8">
            <label class="font-bold block mb-3">Room Name:</label>
            <p class="font-semibold">{{room.name}}</p>
          </div>
        </vs-col>
        <vs-col vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full mb-8">
            <label class="font-bold block mb-3">Age Group:</label>
            <p class="font-semibold">{{ ( "undefined" != typeof room.ageGroup ) ? room.ageGroup.ageGroup + ' Years' : ''}}</p>
          </div>
        </vs-col>
        <vs-col vs-justify="left" vs-align="right" vs-w="6">
          <div class="w-full mb-8">
            <label class="font-bold block mb-3">Room Location:</label>
            <p class="font-semibold">{{room.location}}</p>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/2 mb-base">
        <vx-card class="mb-base room-details-table">
          <vs-table stripe :data="teachers">
            <template slot="header">
              <h2 class="mb-5">Teachers</h2>
            </template>
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Role</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].fullName">
                  <div class="flex items-center">
                    <vs-avatar size="50px" :src="data[indextr].photo" class="mr-6 ml-0" />
                    {{data[indextr].fullName}}
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].role">{{data[indextr].role}}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
      <div class="w-full lg:w-1/2 mb-base">
        <vx-card class="mb-base room-details-table">
          <vs-table stripe :data="children">
            <template slot="header">
              <h2 class="mb-5">Children</h2>
            </template>
            <template slot="thead">
              <vs-th>Name</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].fullName">
                  <div class="flex items-center">
                    <vs-avatar size="50px" :src="data[indextr].photo" class="mr-6 ml-0" />
                    {{data[indextr].fullName}}
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';


export default {
  data() {
    return {
      roomId: this.$route.params.id,
      centerId: this.$route.params.centerId,
      room: {},
      children: [],
      teachers: [],
      centreName: "",
    };
  },
  methods: {
    ...mapActions("room", ["getRoomById"]),
    ...mapActions("center", ["getCenterById"]),
    ...mapActions("centerAdmin", ["deleteCenterAdminRoom"]),

    
    async fetchRoom() {
      this.getRoomById(this.roomId).then((res) => {
        this.room = res.data.data;
        this.children = this.room.children;
        this.teachers = this.room.teachers;
      });
    },
    async fetchCenter() {
      this.getCenterById(this.centerId).then((res) => {
        let center = res.data.data;
        this.centreName = center.name;
      });
    },
    
    editRoom(roomId){
      this.$router.push({
        name: 'director-edit-room',
        params: { 
            centerId:this.centerId,
            roomId: roomId 
          }
      });     

    },
    deleteRoom(roomId){
      Swal.fire({
          title: 'Are you sure you want to delete?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert(roomId)
          }
        })
    },
    acceptDeleteAlert(id) {
        this.$vs.loading()
        this.deleteCenterAdminRoom(id)
          .then((res) => {
            if (res.status === 200) {
              this.$vs.loading.close()
              this.showMessage("Success", "Room deleted successfully.", "success");
            }
            
          })
          .catch(err => {
            this.$vs.loading.close()
          })
          this.$router.push({ name: 'director-room-list', params: { id: this.centerId } } );
    },
  },
  created() {
    this.fetchRoom();
    this.fetchCenter();
  },
  computed: {
     director(){
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {},
};
</script>
